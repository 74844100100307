@import "./colors";
@import "./bootstrap/variables";
@import "./bootstrap/mixins";

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes custom_flip {
    0% {
        -moz-transform: scaleX(1);
        -o-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        filter: FlipH;
        -ms-filter: "FlipH";
    }
    50% {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
    }
    100% {
        -moz-transform: scaleX(1);
        -o-transform: scaleX(1);
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        filter: FlipH;
        -ms-filter: "FlipH";
    }
}

section.index_section{
    padding-bottom: 25px;
    @include media-breakpoint-down(sm){
        > div.container {
            padding:0;
            width:100%;
            > div.row {
                margin:0;
                > div.custom_col{
                    padding:0;
                }
            }
        }
    }
    div.main_pictures_container{
        padding-top: 60px;
        @include media-breakpoint-down(sm){
            padding-top: 30px;
        }
        div.main_pictures_slick{
            margin:0;
            div.content{
                position: relative;
                &.default_height{
                    min-height: calc(1110px / 3);
                    @include media-breakpoint-down(lg){
                        min-height: calc(930px / 3);
                    }
                    @include media-breakpoint-down(md){
                        min-height: calc(690px / 3);
                    }
                    @include media-breakpoint-down(sm){
                        min-height: calc(100vw / 3);
                    }
                }
                a.custom_spin{
                    display: inline-block;
                    &::after{
                        content: '';
                        display: block;
                        width: 3rem;
                        height: 3rem;
                        border-radius: 50%;
                        border: .5rem double $white;
                        border-left: .5rem double $orange_level_2;
                        position: absolute;
                        top: calc(50% - 2rem);
                        left: calc(50% - 2rem);
                        animation: spin 0.75s infinite linear;
                      }
                }

            }
            button.slick-arrow{
                z-index: 3;
                width:auto;
                height: auto;
                &::before{
                    font-family: 'FontAwesome';
                    font-size: 40px;
                }
                &.slick-prev{
                    left:10px;
                    &::before{
                        content: '\f053';
                    }
                }
                &.slick-next{
                    right:10px;
                    &::before{
                        content: '\f054';
                    }
                }
            }
            ul.slick-dots{
                bottom:5px;
                display: inline-block;
                width:auto;
                left:50%;
                transform: translateX(-50%);
                > li{
                    &.slick-active{
                        button{

                        }
                    }
                    button{
                        &::before{
                            font-size: 10px;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

section.menu_section{
    padding:25px 0 100px 0;
    &.-more-height{
        padding-top:100px;
    }
    @include media-breakpoint-down(sm){
        > div.container {
            width:100%;
            > div.row {
                > div.custom_col{
                }
            }
        }
    }
    ul.menu_list{
        margin:0;
        padding:0;
        text-align: center;
        font-size: 0;
        > li{
            margin:0;
            padding:0;
            list-style: none;
            display: inline-block;
            margin-right: 30px;
            font-size: 0;
            &.breakline{
                display: none;
                @include media-breakpoint-down(xs){
                    display: block;
                    border-bottom: 1px solid hsla(0, 0%, 100%, .3);
                    width:80%;
                    margin: 10px auto;
                }
            }
            @include media-breakpoint-down(lg){
                width: 18%;
                margin-right: 10px;
            }
            @include media-breakpoint-down(xs){
                width: 22%;

            }
            &:last-child{
                margin-right: 0;
            }
            > a{
                display: inline-block;
                color: $white;
                text-decoration: none;
                font-size: 1.5rem;
                border-radius: 4px;
                border:1px solid $white;
                padding: 10px 40px;
                text-align: center;
                transition: all .3s;
                @include media-breakpoint-down(lg){
                    padding: 10px 30px;
                }
                @include media-breakpoint-down(md){
                    padding: 10px 20px;
                    font-size: 1.1rem;
                }
                @include media-breakpoint-down(sm){
                    padding: 10px 0;
                    width: 100%;
                    font-size: 1rem;
                }
                @include media-breakpoint-down(xs){
                    font-size: .8125rem;
                }
                &:hover, &:active{
                    color: $orange_level_2;
                    border:1px solid $orange_level_2;
                    transform: scale(1.1);
                    /*box-shadow: 0 0 5px $orange_level_2;*/
                }
                &.temp_d{
                    transform: none;
                    border:1px solid #777;
                    color: #777 !important;
                    box-shadow: none;
                }
                span.hint_external{
                    font-size: .875rem;
                    position: relative;
                    top:-3px;
                    display: inline-block;
                    margin-left:6px;
                    @include media-breakpoint-down(sm){
                        top:0;
                    }
                }

            }
        }
    }
}

section.partners_sec{
  margin-top:50px;
  padding-bottom: 50px;
    @include media-breakpoint-down(sm){
        > div.container {
            width:100%;
            > div.row {
                > div.custom_col{
                }
            }
        }
    }
    div.content_block{
        h2.h2_title{
            margin:0;
            padding:0 0 10px 0;
            text-align: center;
            color: $white;
            font-size: 1.5rem;
            font-weight: normal;
            border-bottom: 1px solid $gray_level_8;
        }
        div.partners_block{
            margin-top: 30px;
            text-align: center;
            ul.partners_list{
                margin:0;
                padding:0;
                > li{
                    padding:0;
                    list-style: none;
                    vertical-align: top;
                    display: inline-block;
                    margin: 0 3px 15px 3px;
                    > a{
                      text-decoration: none;
                      &:hover{
                        span.name{
                          display: block;
                          color: white;
                          text-decoration: underline;
                        }
                      }
                        > img{
                            height: 100px;
                            opacity: 0;
                            transition: opacity .5s ease-in;
                        }
                        &.-disable-link{
                            cursor: default;
                        }
                        &.flip{
                            > img{
                                /*
                                animation-name: custom_flip;
                                animation-duration: 1.5s;
                                animation-iteration-count: 1;
                                animation-timing-function: ease-in-out;
                                */
                                opacity: 1;
                            }
                        }
                        span.name{
                          display: block;
                          color: white;
                        }
                    }
                }
            }
        }
    }
}
