$white: white;
$white_level_2: hsla(0, 0%, 100%, .5);

$black: black;
$black_level_1: #3D3A39;


/*
$blue_level_1: #1C1F87;
$blue_level_2: #00A0E8;
$blue_level_3: #058BD8;
$blue_level_4: #0975C8;
$blue_level_5: #0E60B8;
$blue_level_6: #134AA7;
$blue_level_7: #173597;
$blue_level_8: #1C1F87;
$blue_level_9: hsla(238, 65%, 31%, .8);
*/

$gray_level_1: #C9C9CA;
$gray_level_2: #888889;
$gray_level_3: #EEEEEE;
$gray_level_4: rgba(201, 202, 202, .1);
$gray_level_5: #DDDDDD;
$gray_level_6: #F9F9F9;
$gray_level_7: #E7E7E7;
$gray_level_8: #979797;
$gray_level_9: #717071;

$orange: orange;
$orange_level_1: #f29c00;
$orange_level_2: #F9BC00;
$orange_level_3: #F09927;
